import React, { Component } from 'react'
import {Link} from 'gatsby'
export default class CaseDetailHeader extends Component {
  render() {
    return (
      <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
        <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
          <div className="gdlr-core-pbf-element">
            <div className="gdlr-core-breadcrumbs-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
              {' '}
              <span property="itemListElement" typeof="ListItem">
                <Link to="/">Bora Growth Partners</Link>
                <meta property="position" content={1} />
              </span>•<span property="itemListElement" typeof="ListItem">
                <Link to="/case-studies">Our Cases</Link>
                <meta property="position" content={2} />
              </span>•<span property="itemListElement" typeof="ListItem">
                <span property="name">{this.props.title}</span>
                <meta property="position" content={3} />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
