import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import CaseDetail from '../components/Cases/CaseDetail'
import Hero from '../components/Hero'
export default class MarketResearchCaseStudy extends Component {
  render() {
    const caseTopImage = require('../assets/images/maarten-van-den-heuvel-73123-unsplash.jpg')
    const caseProblemImage = require('../assets/images/maarten-van-den-heuvel-73123-unsplash.jpg')
    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Market Research"
            description="This is how we got the Clothing project market ready"
            withOpacity
          />
          <CaseDetail
            caseClient="Clothing project"
            caseTitle="Clothing project"
            caseDate="17 August 2017"
            caseTopImage={caseTopImage}
            caseProblemImage={caseProblemImage}
            caseHighlight={[
              'A redefined business model and value proposition for the apparel line',
              'We successfully conducted a survey and analysed over 300 potential customers to facilitate successful market entry in Uganda, Kenya and Tanzania.',
              'Our work led to a 50% cost reduction in key input through consolidated local sourcing as a result of the market research conducted',
              'Over 25% customer retention rate through contracting model identified through analysed customer research',
              '5 identified social Influencers with consistent alignment to the brand; leading to an improved awareness in the region.',
            ]}
            caseDescription="Our client, a clothing manufacturing company in East Africa had identified a viable
            business opportunity within textile sector in East African region. "
            caseProblemDescription="The main challenge was lead time on ordering, optimized process flow, unestablished customer retention and poor market penetration for their clothing products. This led to poor profit margins for the extended period of time and inability to fully address the opportunity at hand."
            caseProblemDescriptionHighlight="Bora Growth Partners was hired to investigate, design and implement a turnaround plan and map the optimized flow that would unlock revenue potential for the business. The main focus was in acquiring new customers and retain existing ones."
            caseFeedback="We were able to rely on Bora Growth team in doing the work we did not have in-house support for. They managed to take our senior team to East Africa and this showed immediate results. KUDOS guys"
          />
        </div>
      </Layout>
    )
  }
}
