import React, { Component } from 'react'
import CaseDetailHeader from './CaseDetailHeader'
import { Link } from 'gatsby'
export default class CaseDetail extends Component {
  renderTopSection() {
    const icon1 = require('../../assets/images/icon-1.png')
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ margin: '0px 0px 0px 0px', padding: '90px 0px 25px 0px' }}
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                style={{
                  margin: '-170px 0px 0px 0px',
                  padding: '0px 0px 0px 0px',
                }}
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                      <div
                        className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-with-shadow gdlr-core-image-item-style-rectangle"
                        style={{
                          boxShadow: '0 0 74px rgba(15, 15, 15,0.07)',
                          MozBoxShadow: '0 0 74px rgba(15, 15, 15,0.07)',
                          WebkitBoxShadow: '0 0 74px rgba(15, 15, 15,0.07)',
                          borderWidth: 0,
                        }}
                      >
                        <img
                          src={this.props.caseTopImage}
                          alt
                          width={595}
                          height={580}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-30">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                style={{ padding: '0px 0px 40px 30px' }}
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h4
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 27,
                            fontWeight: 600,
                            letterSpacing: 0,
                            textTransform: 'none',
                          }}
                        >
                          {this.props.caseTitle}
                          <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{ fontSize: 16 }}
                      >
                        <p>{this.props.caseDescription}</p>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-port-info-item gdlr-core-item-pdb  gdlr-core-item-pdlr"
                      style={{ paddingBottom: 50 }}
                    >
                      <div className="gdlr-core-port-info-wrap gdlr-core-skin-divider ">
                        <div className="gdlr-core-port-info">
                          <span className="gdlr-core-port-info-key gdlr-core-skin-title">
                            Client
                          </span>
                          <span className="gdlr-core-port-info-value">
                            {this.props.caseClient}
                          </span>
                        </div>
                        <div className="gdlr-core-port-info">
                          <span className="gdlr-core-port-info-key gdlr-core-skin-title">
                            Date
                          </span>
                          <span className="gdlr-core-port-info-value">
                            {this.props.caseDate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gdlr-core-pbf-column gdlr-core-column-36 gdlr-core-column-first"
              data-skin="Purple Service"
            >
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                style={{ padding: '50px 0px 0px 0px' }}
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h4
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 25,
                            fontWeight: 600,
                            letterSpacing: 0,
                            textTransform: 'none',
                            color: '#2b2b2b',
                          }}
                        >
                          The problem<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{ fontSize: 16 }}
                      >
                        <p>{this.props.caseProblemDescription}</p>
                        <p>
                          <span style={{ fontSize: 21 }}>
                            {this.props.caseProblemDescriptionHighlight}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-24">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                      <div
                        className="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-with-shadow gdlr-core-image-item-style-rectangle"
                        style={{
                          boxShadow: '0 0 74px rgba(22, 22, 22,0.07)',
                          MozBoxShadow: '0 0 74px rgba(22, 22, 22,0.07)',
                          WebkitBoxShadow: '0 0 74px rgba(22, 22, 22,0.07)',
                          borderWidth: 0,
                        }}
                      >
                        <img
                          src={this.props.caseProblemImage}
                          alt
                          width={396}
                          height={569}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCaseHighlights() {
    this.props.serviceHighlight ? (
      this.props.caseHighlight.map(highlight => {
        return (
          <li className=" gdlr-core-skin-divider">
            <span className="gdlr-core-icon-list-icon-wrap">
              <i
                className="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                style={{
                  color: '#313787',
                  fontSize: 17,
                  width: 17,
                }}
              />
            </span>
            <span
              className="gdlr-core-icon-list-content"
              style={{ fontSize: 16 }}
            >
              {highlight}
            </span>
          </li>
        )
      })
    ) : (
      <div />
    )
  }
  renderBottomSection() {
    const icon1 = require('../../assets/images/icon-1.png')

    return (
      <div className="gdlr-core-pbf-wrapper ">
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 22,
                            fontWeight: 600,
                            letterSpacing: 0,
                            textTransform: 'none',
                          }}
                        >
                          What We’ve Done<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-40">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                style={{
                  margin: '0px 0px 0px 0px',
                  padding: '0px 0px 35px 0px',
                }}
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      style={{ paddingBottom: 0 }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{ fontSize: 16 }}
                      >
                        <p>
                          <span style={{ fontSize: 22 }}>
                            <span style={{ color: '#3039ac' }}>
                              To ensure that
                            </span>{' '}
                            we addressed these concerns for{' '}
                            <b>{this.props.caseClient}</b>, we reached to our
                            diverse network and in-house team to formulate an
                            actionable plan of action
                          </span>
                        </p>
                        {/* <p>
                          More than 25 years of experience working in the
                          industry has enabled us to build our services and
                          solutions in strategy, consulting, digital, technology
                          and operations that help our clients with their
                          trading projects around the world. Capabilities we
                          leverage for our clients include
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 22,
                            fontWeight: 600,
                            letterSpacing: 0,
                            textTransform: 'none',
                          }}
                        >
                          Our Impact<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-40">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                style={{
                  margin: '0px 0px 0px 0px',
                  padding: '0px 0px 35px 0px',
                }}
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      style={{ paddingBottom: 6 }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{ fontSize: 16 }}
                      >
                        <p>
                          Have a look at some of our achievements on working on
                          this project from {this.props.caseClient}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb "
                      style={{ paddingBottom: 10 }}
                    >
                      <ul>{this.renderCaseHighlights()}</ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 22,
                            fontWeight: 600,
                            letterSpacing: 0,
                            textTransform: 'none',
                          }}
                        >
                          Feedback From Client<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-40">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                style={{
                  margin: '0px 0px 0px 0px',
                  padding: '0px 0px 35px 0px',
                }}
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                      style={{ paddingBottom: 6 }}
                    >
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{ fontSize: 16 }}
                      >
                        <p>{this.props.caseFeedback}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCaseStudyFooter() {
    return (
      <div className="gdlr-core-portfolio-single-nav-wrap">
        <div className="gdlr-core-container">
          <div className="gdlr-core-portfolio-single-nav-area gdlr-core-item-pdlr clearfix">
            <span className="gdlr-core-portfolio-single-nav gdlr-core-portfolio-single-nav-left">
              <Link to="/case-studies" rel="prev">
                <i className="arrow_left" />
                <span className="gdlr-core-portfolio-text">Prev</span>
              </Link>
            </span>
            <span className="gdlr-core-portfolio-single-nav gdlr-core-portfolio-single-nav-right">
              <Link to="/case-studies" rel="next">
                <span className="gdlr-core-portfolio-text">Next</span>
                <i className="arrow_right" />
              </Link>
            </span>
          </div>
        </div>
      </div>
    )
  }

  renderViewAllButton() {
    return (
      <div className="gdlr-core-pbf-element">
        <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
          <Link
            className="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border"
            to="/case-studies"
            style={{
              fontSize: 15,
              color: '#ffffff',
              padding: '17px 38px 23px 38px',
              textTransform: 'none',
              borderRadius: 33,
              MozBorderRadius: 33,
              WebkitBorderRadius: 33,
              background: '#2d5fde',
            }}
          >
            <span className="gdlr-core-content">View All Cases</span>
          </Link>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div>
        {this.renderTopSection()}
        {this.renderBottomSection()}
        {this.renderCaseStudyFooter()}
      </div>
    )
  }
}
